import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PopupDine.css';

const PopupDine = ({ showPopup, handleOrderTypeSelect }) => {
    const navigate = useNavigate();

    if (!showPopup) return null;

    return (
        <div className="dinein-popup-overlay">
            <div className="dinein-popup" onClick={(e) => e.stopPropagation()}>
                <button
                    className="dinein-popup-close-icon"
                    onClick={() => navigate(-1)}
                    aria-label="Close"
                >
                    &times;
                </button>
                <h3 className="dinein-popup-header">HOW WOULD YOU LIKE TO RECEIVE YOUR ORDER</h3>
                <div className="dinein-popup-options">
                    <button className="dinein-popup-button" onClick={() => handleOrderTypeSelect('Dinein')}>Dine In</button>
                    <button className="dinein-popup-button" onClick={() => handleOrderTypeSelect('Takeaway')}>Take Away</button>
                </div>
            </div>
        </div>
    );
};

export default PopupDine;
