import React, { useState, useEffect, useRef } from "react";
import "./header.css";
import { motion } from "framer-motion";
import toplogo from "../../../images/top-logo.svg";
import icecreammainimage from "../../../images/Group 72.png";

import { isMobile } from "react-device-detect";
import MobHeader from "../../Mob/MobHeader/MobHeader";
import user1 from "../../../images/Desktop-icons/user-hand-up-.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

const Header = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const [homepage, sethome] = useState([]);

  const popupRef = useRef(null);  // Reference for the popup

  const handleNavClick = (link, path) => {
    setActiveLink(link);
    navigate(path);
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleBulkOrderClick = () => {
    setIsPopupOpen(!isPopupOpen); // Toggle popup visibility
  };

  const handleOptionClick = (path) => {
    navigate(path);
    setIsPopupOpen(false); // Close popup after navigation
  };
  const fetchhomedatas = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/homepage/allon`);
      console.log(response.data, "kk");
      sethome(response.data);
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  useEffect(() => {
    fetchhomedatas();
  }, []);
  // Detect click outside of the popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false); // Close the popup when clicking outside
      }
    };

    if (isPopupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  const subText =
    "Dive into a frozen paradise and let our ice cream take you to a land of pure ecstasy.";

  return isMobile ? (
    <MobHeader />
  ) : (
    <div className="header">
      <div className="navbar1">
        <p className="need-help">Need help? Call Us: +1834 123 456 789</p>
        <img src={toplogo} className="top-logo" alt="Top Logo" />
        {userData?.user ? (
          <>
            <motion.button
              className="bulk-order-button"
              whileHover={{ scale: 1.1 }}
              onClick={handleBulkOrderClick}
            >
              <img src={user1} className="top-user-logo" alt="User Logo" />
              {userData?.user?.firstname}
            </motion.button>
          </>
        ) : (
          <>
            <motion.button
              className="bulk-order-button"
              whileHover={{ scale: 1.1 }}
              onClick={handleLoginClick}
            >
              <img src={user1} className="top-user-logo" alt="User Logo" />
              Login
            </motion.button>
          </>
        )}
      </div>

      {isPopupOpen && (
        <div className="bulk-order-popup" ref={popupRef}>
          <motion.div
            className="popup-option"
            whileHover={{ scale: 1.1 }}
            onClick={() => handleOptionClick("/menu")}
          >
                        Menu

          </motion.div>
          <motion.div
            className="popup-option"
            whileHover={{ scale: 1.1 }}
            onClick={() => handleOptionClick("/cart")}
          >
            Cart
          </motion.div>
          <motion.div
            className="popup-option"
            whileHover={{ scale: 1.1 }}
            onClick={() => handleOptionClick("/order")}
          >Order
          </motion.div>
        </div>
      )}

      <div className="navbar2">
        <div className="nav-links left-links">
          <motion.div
            className="nav-item"
            whileHover={{ scale: 1.1 }}
            onClick={() => handleNavClick("home", "/")}
          >
            <p className="nav-link">HOME</p>
            {activeLink === "home" && <div className="nav-sub-link"></div>}
          </motion.div>
          <motion.div
            className="nav-item"
            whileHover={{ scale: 1.1 }}
            onClick={() => handleNavClick("about", "/about")}
          >
            <p className="nav-link">ABOUT US</p>
            {activeLink === "about" && <div className="nav-sub-link"></div>}
          </motion.div>
          <motion.div
            className="nav-item"
            whileHover={{ scale: 1.1 }}
            onClick={() => handleNavClick("products", "/menu")}
          >
            <p className="nav-link">PRODUCTS</p>
            {activeLink === "products" && <div className="nav-sub-link"></div>}
          </motion.div>
        </div>
        <div className="nav-links right-links">
          <motion.div
            className="nav-item"
            whileHover={{ scale: 1.1 }}
            onClick={() => handleNavClick("ourstory", "/blog")}
          >
            <p className="nav-link">OUR STORY</p>
            {activeLink === "ourstory" && <div className="nav-sub-link"></div>}
          </motion.div>
          <motion.div
            className="nav-item"
            whileHover={{ scale: 1.1 }}
            onClick={() => handleNavClick("blog", "/form")}
          >
            <p className="nav-link">BULK ORDER</p>
            {activeLink === "blog" && <div className="nav-sub-link"></div>}
          </motion.div>
          <motion.div
            className="nav-item"
            whileHover={{ scale: 1.1 }}
            onClick={() => handleNavClick("contact", "/contact")}
          >
            <p className="nav-link">CONTACT US</p>
            {activeLink === "contact" && <div className="nav-sub-link"></div>}
          </motion.div>
        </div>
      </div>

      <div className="header-content">
        <div className="header-text">
          <motion.h1 className="main-text" whileHover={{ scale: 1.1 }}>
            {homepage[0]?.title}
          </motion.h1>
          <p className="sub-text">
            { homepage[0]?.subtitle.split(" ").map((el, i) => (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.25, delay: i / 10 }}
                key={i}
              >
                {el}{" "}
              </motion.span>
            ))}
          </p>
          <motion.div
            className="discover-button"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 2 }}
            onClick={()=>{navigate( homepage[0]?.link)}}

          >
            DISCOVER NOW
          </motion.div>
        </div>
        <motion.img
      src={icecreammainimage}
      className="ice-cream-image"
      alt="Ice Cream"
      initial={{ y: -200 }}  
      animate={{ y: 0 }}    
      transition={{
        duration: 1,         
        ease: 'easeOut',     
      }}
      whileHover={{ scale: 1.05 }}  
    />
      </div>
    </div>
  );
};

export default Header;
