import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import Footer from "../../components/Web/Footer/Footer";
import helpImage from "../../images/Desktop-icons/about-svgrepo-com.svg";
import phoneImage from "../../images/Desktop-icons/phone-svgrepo.svg";
import MobContactUs from "../../components/Mob/MobContact/MobContactUs";
import axios from "axios";
import { isMobile } from "react-device-detect";
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASEURL;

const ContactUs = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [issue, setIssue] = useState("");
  const [questions, setQuestions] = useState("");
  const [blogData, setBlogData] = useState([]); 
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  console.log(userData?.user, "jjj");
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/blog/alloncontactus`);
        setBlogData(response.data); 
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };
    fetchBlogData();
  }, []);
  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/user/singleprofile/${userData?.user?._id}`)
      setData(response.data);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
    }
  };
useEffect(()=>{
  fetchDetails()
},[])
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleIssueChange = (e) => {
    setIssue(e.target.value);
  };

  const handleQuestionsChange = (e) => {
    setQuestions(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (email && issue && questions) {
      await axios.post(`${baseUrl}/api/admin/contactus`, {
        email: email,
        helpoption: issue,
        questions: questions,
      });
      setPopupOpen(false);
    } else {
      alert("Please fill out all required fields.");
    }
  };

  return isMobile ? (
    <MobContactUs />
  ) : (
    <div>
      <Navbardesktop/>
      <div className="contact-help-section">
        <div className="contact-help-content">
          <div className="contact-help-left">
            <img
              src={helpImage}
              alt="Help Center"
              className="contact-help-image"
            />
            <div>
              <p>Please get in touch and we will be happy to help you</p>
            </div>
          </div>
          <div className="contact-help-right">
            <button className="contact-write-to-us" onClick={togglePopup}>
              Write to Us
            </button>
            <div className="contact-phone-contact">
              <img
                src={phoneImage}
                alt="Phone"
                className="contact-phone-image"
              />
              <span className="contact-phone-number">+64224030247</span>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-hero-section">
        <div className="contact-hero-content">
          <h1>Get in touch</h1>
          <p>Discover the art of flavors in every bite.</p>
        </div>
      </section>
      <div className="contact-about-us-section">
        {blogData.map((item, index) => (
        <div className="contact-content-block" key={index}>
            <h2>{item?.title}</h2>
            <p>{item?.description}</p>
            {/* <img
              src={item?.image}
              alt={item.title}
              className="contact-blog-image"
            /> */}
          </div>
        ))}
      </div>

      <Footer />

      {isPopupOpen && (
        <div className="contact-aboutus-popup">
          <div className="contact-aboutus-popup-content">
            <span className="contact-aboutus-close" onClick={togglePopup}>
              &times;
            </span>
            <h2 className="contact-aboutus-popuphead">Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <div className="contact-aboutus-form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  className="contact-aboutus-input"
                  required
                />
              </div>
              <div className="contact-aboutus-form-group">
                <label htmlFor="issue">How can we help you?</label>
                <select
                  id="issue"
                  value={issue}
                  onChange={handleIssueChange}
                  className="contact-aboutus-select"
                  required
                >
                  <option value="">Select an option</option>
                  <option value="Order Related Issue">Order Related Issue</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="contact-aboutus-form-group">
                <label htmlFor="questions">Any other questions?</label>
                <textarea
                  id="questions"
                  value={questions}
                  onChange={handleQuestionsChange}
                  placeholder="Type your questions here"
                  className="contact-aboutus-textarea"
                  required
                />
              </div>
              <button type="submit" className="contact-aboutus-submit">
                Submit
              </button>
            </form>

          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
