import React, { useState, useEffect } from "react";
import "./MobSignupPage.css";
import backIcon from "../../../images/Mobile-icons/Backbutton.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASEURL;

const MobSignupPage = () => {
  const navigate = useNavigate();

  const [filledFields, setFilledFields] = useState({
    email: false,
    mobile: false,
    password: false,
    confirmPassword: false,
  });

  const [formValues, setFormValues] = useState({
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
  });

  const [validationMessages, setValidationMessages] = useState({
    email: "",
    mobile: "",
    password: [],
    confirmPassword: "",
  });

  const [isButtonActive, setIsButtonActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let emailMessage = "";
    let mobileMessage = "";
    let passwordMessages = [];
    let confirmPasswordMessage = "";

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      emailMessage = !emailRegex.test(value) ? "Enter a valid email address." : "";
    }

    if (name === "mobile") {
      const mobileRegex = /^[0-9]{10}$/;
      mobileMessage = !mobileRegex.test(value) ? "Enter a valid 10-digit mobile number." : "";
    }

    if (name === "password") {
      if (!/(?=.*[A-Z])/.test(value)) {
        passwordMessages.push("Add a capital letter");
      }
      if (!/(?=.*\d)/.test(value)) {
        passwordMessages.push("Add a number");
      }
      if (!/(?=.*[@$!%*?&])/.test(value)) {
        passwordMessages.push("Add a special character (e.g. @, $, !, %, *, ?, &)");
      }
      if (value.length < 6) {
        passwordMessages.push("Password must be at least 6 characters long");
      }
      if (formValues.confirmPassword && value !== formValues.confirmPassword) {
        confirmPasswordMessage = "Passwords do not match.";
      }
    }

    if (name === "confirmPassword") {
      confirmPasswordMessage =
        value !== formValues.password ? "Passwords do not match." : "";
    }

    setValidationMessages((prevState) => ({
      ...prevState,
      email: emailMessage,
      mobile: mobileMessage,
      password: passwordMessages,
      confirmPassword: confirmPasswordMessage,
    }));

    setFilledFields((prevState) => ({
      ...prevState,
      [name]:
        !!value &&
        emailMessage === "" &&
        mobileMessage === "" &&
        passwordMessages.length === 0 &&
        confirmPasswordMessage === "",
    }));
  };

  useEffect(() => {
    const allFieldsFilled = Object.values(filledFields).every(Boolean);
    setIsButtonActive(allFieldsFilled);
  }, [filledFields]);

  const progress = Object.values(filledFields).filter(Boolean).length * 25;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseUrl}/api/otp/`, {
        email: formValues.email,
      });
      await axios.post(`${baseUrl}/api/user/checkuser`, {
        email: formValues.email,
        mobile: formValues.mobile,
      });

      const responseData = {
        email: formValues.email,
        mobile: formValues.mobile,
        password: formValues.password,
      };
      localStorage.setItem("userData", JSON.stringify(responseData));

      toast.success("Signup successful!", { className: "mob-toast " });
      navigate("otppage");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      toast.error(errorMessage, { className: "mob-toast " });
    }
  };

  return (
    <div>
      <div className="mob-signup-navbar">
        <img
          src={backIcon}
          alt="Back"
          className="mob-signup-back-icon"
          onClick={() => navigate(-1)}
        />
        <p className="mob-signup-title">Create Account</p>
      </div>
      <div className="mob-signup-main-head"></div>
      <div className="mob-signup-main-body">
        <div className="mob-signup-main-steps">
          <div
            className={`mob-signup-main-step mob-signup-main-step-1 ${
              progress >= 25 ? "progress-25" : ""
            }`}
          >
            1
          </div>
          <div
            className={`mob-signup-main-line ${
              progress >= 25 ? "progress-25" : ""
            } ${progress >= 50 ? "progress-50" : ""} ${
              progress >= 75 ? "progress-75" : ""
            } ${progress === 100 ? "progress-100" : ""}`}
          ></div>
          <div
            className={`mob-signup-main-step mob-signup-main-step-2 ${
              progress >= 50 ? "progress-50" : ""
            }`}
          >
            2
          </div>
          <div
            className={`mob-signup-main-step mob-signup-main-step-3 ${
              progress >= 75 ? "progress-75" : ""
            }`}
          >
            3
          </div>
        </div>
      </div>
      <div className="mob-signup-main-login-section">
        <h2 className="mob-signup-main-login-head">Sign Up to Manna Delights</h2>
        <div className="mob-signup-main-fields-container">
          <div className="mob-signup-main-field">
            <p>Email ID</p>
            <input
              type="email"
              name="email"
              placeholder="Enter your Email"
              onChange={handleInputChange}
              className={validationMessages.email ? "input-error" : ""}
            />
            <p className="mob-signup-main-validation-message">
              {validationMessages.email}
            </p>
          </div>
          <div className="mob-signup-main-field">
            <p>Mobile Number</p>
            <input
              type="text"
              name="mobile"
              placeholder="Enter your Mobile number*"
              onChange={handleInputChange}
              className={validationMessages.mobile ? "input-error" : ""}
            />
            <p className="mob-signup-main-validation-message">
              {validationMessages.mobile}
            </p>
            <p className="mob-signup-main-otp-placeholder">
              OTP will be sent to this number
            </p>
          </div>
          <div className="mob-signup-main-field">
        <p>Password</p>
        <div className="input-container">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Enter New Password"
            className="mob-signup-main-input"
          />
          <span
            className="eye-icon"
            onClick={handlePasswordVisibility}
            style={{ cursor: "pointer" }}
          >
            👁️
          </span>
        </div>
        <div className="mob-signup-main-validation-message">
          {validationMessages.password.map((msg, idx) => (
            <p key={idx}>{msg}</p>
          ))}
        </div>
      </div>

      <div className="mob-signup-main-field">
        <p>Confirm Password</p>
        <div className="input-container">
          <input
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder="Re-Enter Password*"
            className="mob-signup-main-input"
          />
          <span
            className="eye-icon"
            onClick={handleConfirmPasswordVisibility}
            style={{ cursor: "pointer" }}
          >
            👁️
          </span>
        </div>
        <p className="mob-signup-main-validation-message">
          {validationMessages.confirmPassword}
        </p>
      </div>
        </div>
        <div className="mob-signup-main-fields-buttons">
          <button
            className={`mob-signup-main-button ${
              isButtonActive ? "active" : "inactive"
            }`}
            type="submit"
            onClick={handleSubmit}
            disabled={!isButtonActive}
          >
            Sign Up
          </button>
          <p>
            Already have an account? <a href="#">Login</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobSignupPage;
