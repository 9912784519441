import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./MobLoginpage.css";
import userIcon from "../../../images/Desktop-icons/icecream-login-icon.svg";
import helpcenterlogo from "../../../images/Desktop-icons/help-center.svg";
import backIcon from "../../../images/Mobile-icons/Backbutton.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASEURL;

const MobLoginPage = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isPopupOpen) {
      document.body.classList.add("mob-login-no-scroll");
    } else {
      document.body.classList.remove("mob-login-no-scroll");
    }
    return () => document.body.classList.remove("mob-login-no-scroll");
  }, [isPopupOpen]);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const goBack = () => navigate(-1);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post(`${baseUrl}/api/user/login`, values);
        const userData = response.data;
        localStorage.setItem("userData", JSON.stringify(userData));
        toast.success(response.data.message, { className: "custom-toast" });
        navigate("/");
      } catch (error) {
        const message =
          error.response?.data?.message || "An unexpected error occurred";
        toast.error(message, { className: "custom-toast" });
      }
    },
  });

  return (
    <div>
      <div className="mob-login-navbar">
        <img
          src={backIcon}
          alt="Back"
          className="mob-login-back-icon"
          onClick={goBack}
        />
        <p className="mob-login-navbar-title">Account</p>
      </div>
      <div className="mob-login-navbar-shadow"></div>
      <div className="mob-login-page-container">
        <div className="mob-login-content">
          <img src={userIcon} alt="User Icon" className="mob-login-user-icon" />
          <p className="mob-login-welcome-text">WELCOME TO MANNA DELIGHTS</p>
          <div className="mob-login-content-button-sec">
            <button className="mob-login-signup-button" onClick={openPopup}>
              Login
            </button>
            <button
              className="mob-login-signup-button"
              onClick={() => navigate("/signup")}
            >
              Signup
            </button>
          </div>
        </div>
      </div>
      <div className="mob-login-help-center-section">
        <img
          src={helpcenterlogo}
          alt="Help Center Icon"
          className="mob-login-help-center-icon"
        />
        <a href="/help-center" className="mob-login-help-center-link">
          Help Center
        </a>
      </div>
      {isPopupOpen && (
        <div className="mob-login-popup-container">
          <div className="mob-login-popup-box">
            <button className="mob-login-close-button" onClick={closePopup}>
              Skip
            </button>
            <div className="mob-login-header">
              <h1>Login</h1>
            </div>
            <form className="mob-login-form" onSubmit={formik.handleSubmit}>
              <div className="mob-login-field">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="error">{formik.errors.email}</p>
                )}
              </div>
              <div className="mob-login-field">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter Password"
                  required
                />
                {formik.touched.password && formik.errors.password && (
                  <p className="error">{formik.errors.password}</p>
                )}
              </div>
              <div className="mob-login-notsign-field">
                <p>
                  Don't have an account? <span>SignUp</span>
                </p>
              </div>
              <div className="mob-login-actions">
                <button type="submit" className="mob-login-button">
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobLoginPage;
