import React, { useState, useEffect } from "react";
import "./SignupPage.css";
import icevan from "../../images/Desktop-icons/ice-cream-van.svg";
import { isMobile } from "react-device-detect";
import MobSignupPage from "../../components/Mob/MobLogin/MobSignupPage";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";

const baseUrl = process.env.REACT_APP_BASEURL;

const SignupPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [eyeOpen, setEyeOpen] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [filledFields, setFilledFields] = useState({
    email: false,
    mobile: false,
    password: false,
    confirmPassword: false,
  });
  const [formValues, setFormValues] = useState({
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
  });

  const [validationMessages, setValidationMessages] = useState({
    password: [],
    confirmPassword: "",
    email: "",
    mobile: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const handlePasswordVisibility = () => {
    setEyeOpen(!eyeOpen);

    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  // const handlePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  //   setEyeOpen(!eyeOpen);
  // };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let passwordMessages = [];
    let confirmPasswordMessage = "";
    let emailMessage = "";
    let mobileMessage = "";

    if (name === "email") {
      const isValidEmail = value.includes("@");
      emailMessage = isValidEmail ? "" : "Email must contain @ symbol.";
      setFilledFields((prevState) => ({
        ...prevState,
        email: isValidEmail,
      }));
    }

    if (name === "mobile") {
      const isNumeric = /^\d+$/.test(value);
      mobileMessage = isNumeric ? "" : "Mobile number must contain only numbers.";
      setFilledFields((prevState) => ({
        ...prevState,
        mobile: isNumeric,
      }));
    }

    // Password validation
    if (name === "password") {
      if (!/(?=.*[A-Z])/.test(value)) {
        passwordMessages.push("Add a capital letter");
      }
      if (!/(?=.*\d)/.test(value)) {
        passwordMessages.push("Add a number");
      }
      if (!/(?=.*[@$!%*?&])/.test(value)) {
        passwordMessages.push(
          "Add a special character (e.g. @, $, !, %, *, ?, &)"
        );
      }
      if (value.length < 8) {
        passwordMessages.push("Password must be at least 8 characters long");
      }

      if (formValues.confirmPassword && value !== formValues.confirmPassword) {
        confirmPasswordMessage = "Passwords do not match.";
      }
    }

    if (name === "confirmPassword") {
      confirmPasswordMessage =
        value !== formValues.password ? "Passwords do not match." : "";
    }

    setValidationMessages((prevState) => ({
      ...prevState,
      password: passwordMessages,
      confirmPassword: confirmPasswordMessage,
      email: emailMessage,
      mobile: mobileMessage,
    }));

    setFilledFields((prevState) => ({
      ...prevState,
      [name]:
        !!value &&
        passwordMessages.length === 0 &&
        confirmPasswordMessage.length === 0 &&
        emailMessage.length === 0 &&
        mobileMessage.length === 0,
    }));
  };

  useEffect(() => {
    const allFieldsFilled = Object.values(filledFields).every(Boolean);
    setIsFormValid(allFieldsFilled);
  }, [filledFields]);

  const progress = Object.values(filledFields).filter(Boolean).length * 25;

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(`${baseUrl}/api/otp/`, {
        email: values.email,
      });
      await axios.post(`${baseUrl}/api/user/checkuser`, {
        email: values.email,
        mobile: values.mobile,
      });
      const responseData = {
        email: values.email,
        mobile: values.mobile,
        password: values.password,
      };
      localStorage.setItem("userData", JSON.stringify(responseData));
      toast.success("Signup successful!", { className: "custom-toast" });
      navigate("otppage");
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An unexpected error occurred";
      toast.error(errorMessage, { className: "custom-toast" });
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Email is required."),
      mobile: Yup.string()
      .matches(
        /^\+?[1-9]\d{1,14}$/,
        "Mobile number must be a valid international number."
      )
      .required("Mobile number is required."),
    
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(/[!@#$%^&*]/, "Password must contain at least one special character")
      .required("Password is required."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required."),
  });
  return isMobile ? (
    <MobSignupPage />
  ) : (
    <div>
      <ToastContainer />
      <Navbardesktop/>
      <div className="sign-main-head"></div>
      <div className="sign-main-body">
        <div className="sign-main-steps">
          <div className="sign-main-step sign-main-step-1">1</div>
          <div
            className={`sign-main-line ${progress >= 25 ? "progress-25" : ""} ${
              progress >= 50 ? "progress-50" : ""
            } ${progress >= 75 ? "progress-75" : ""} ${
              progress === 100 ? "progress-100" : ""
            }`}
          ></div>
          <div className="sign-main-step sign-main-step-2">2</div>
          <div className="sign-main-step sign-main-step-3">3</div>
          <img
            src={icevan}
            alt="Ice Cream Van"
            className={`sign-main-ice-van progress-${progress}`}
          />
        </div>
      </div>
      <div className="sign-main-login-section">
       
        <Formik
          initialValues={{
            email: "",
            mobile: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="sign-main-login-section">
              <h2 className="sign-main-login-section-head">Sign Up to Manna Delights</h2>
              <div className="sign-main-fields-container">
                <div className="sign-main-field">
                  <p className="sign-main-login-section-p">Email ID</p>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Enter your Email"
                    className="sign-main-input"
                  />
                  <ErrorMessage name="email" component="p" className="sign-main-validation-message" />
                </div>

                <div className="sign-main-field">
                  <p className="sign-main-login-section-p">Mobile Number</p>
                  <Field
                    type="text"
                    name="mobile"
                    placeholder="Enter your Mobile number*"
                    className="sign-main-input"
                  />
                  <ErrorMessage name="mobile" component="p" className="sign-main-validation-message" />
                  <p className="sign-main-otp-placeholder">OTP will be sent to this number</p>
                </div>

                <div className="sign-main-field">
                  <p className="sign-main-login-section-p">Password</p>
                  <div className="password-input-container">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter New Password"
                      className="sign-main-input"
                    />
                    <span
                      className={`eye-icon ${eyeOpen ? "eye-open" : "eye-closed"}`}
                      style={{ cursor: "pointer" }}
                      onClick={handlePasswordVisibility}
                    >
                      👁️
                    </span>
                  </div>
                  <ErrorMessage name="password" component="p" className="sign-main-validation-message" />
                </div>

                <div className="sign-main-field">
        <p className="sign-main-login-section-p">Confirm Password</p>
        <div className="password-input-container">
          <Field
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder="Re-Enter Password*"
            className="sign-main-input"
          />
          <span
            className="eye-icon"
            style={{ cursor: "pointer" }}
            onClick={handleConfirmPasswordVisibility}
          >
            👁️
          </span>
        </div>
        <ErrorMessage name="confirmPassword" component="p" className="sign-main-validation-message" />
      </div>
    </div>

              <div className="sign-main-fields-buttons">
                <button
                  className="main-signup-button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Sign Up
                </button>
                <p className="sign-main-fields-p">
                  Already have an account? <a href="/login">Login</a>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignupPage;
